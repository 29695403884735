.mainSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 20px;
}

.noBooks-Message {
  width: 90%;
  min-height: 25rem;
  text-align: center;
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;
  color: #121212;
  opacity: 0.5;
  font-weight: 300;
}

.construction {
  width: 90%;
  min-height: 25rem;
  text-align: center;
  font-size: 3rem;
  font-family: 'Montserrat', sans-serif;
  color: #121212;
  opacity: 0.5;
  font-weight: 700;
  margin: auto;
}

.loadingState {
  width: 90%;
  min-height: 25rem;
}

.booksHolder {
  width: 90%;
  min-height: 25rem;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
