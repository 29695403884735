.SinglebookArrange {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border: solid 1px #e8e8e8;
  margin: 0;
  padding: 15px;
  padding-left: 20px;
  background-color: white;
  flex-wrap: wrap;
}

.bookImfo span {
  font-family: 'Montserrat', sans-serif;
  font-size: 0.875rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #121212;
  opacity: 0.5;
}

.bookImfo h2 {
  font-family: 'Roboto Slab', serif;
  font-size: 1.375rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: #121212;
  margin: 0;
}

.bookImfo h3 {
  font-family: 'Roboto Slab', serif;
  font-size: 0.875rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4386bf;
  margin: 0;
  margin-bottom: 10px;
}

.bookImfo button {
  font-family: 'Roboto Slab', serif;
  font-size: 0.875rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4386bf;
  background-color: transparent;
  border: 0;
  border-right: 3px solid #e8e8e8;
  cursor: pointer;
  padding-left: 8px;
  padding-right: 5px;
}

.otherImfo button {
  border-radius: 3px;
  background-color: #0290ff;
  border: 0;
  padding: 10px 20px;
  font-family: 'Roboto Slab', serif;
  font-size: 0.813rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: center;
  color: white;
  margin-top: 12px;
  cursor: pointer;
}

.bookImfo button:nth-of-type(1) {
  padding-left: 0;
}

.bookImfo button:nth-of-type(3) {
  border-right: 0;
}

.percentagesData .per {
  font-family: 'Montserrat', sans-serif;
  font-size: 2rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #121212;
}

.percentagesData .comp {
  opacity: 0.5;
  font-family: 'Montserrat', sans-serif;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #121212;
}

.separatorLine {
  height: 100px;
  border: solid 1px #e8e8e8;
}

.otherImfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 6px;
  position: relative;
  right: 100px;
}

.otherImfo .Curchapter {
  opacity: 0.5;
  font-family: 'Roboto Slab', serif;
  font-size: 0.813rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #121212;
}

.otherImfo .chapter {
  font-family: 'Roboto Slab', serif;
  font-size: 1rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.4px;
  color: #121212;
}
