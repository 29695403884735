@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&family=Roboto+Slab:wght@300;700&display=swap');

body {
  background-color: #f5f6fa;
  display: flex;
  align-items: center;
  justify-content: center;
}

#root {
  width: 80%;
  min-height: 80%;
  margin-top: 4rem;
  margin-bottom: 4rem;
  border-radius: 4px;
  box-shadow: 6px 20px 36px 0 rgba(0, 0, 0, 0.404);
  background-color: rgba(225, 224, 224, 0.29);
}

.wholeApp {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

ul {
  list-style-type: none;
  display: flex;
  column-gap: 10px;
}

a {
  text-decoration: none;
}

.progress {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 50px;
}

.perDisplay {
  border: 5px solid royalblue;
  border-left: 5px solid rgb(170, 170, 170);
  border-radius: 50%;
  transform: rotate(25deg);
  width: 70px;
  height: 70px;
}

.percentagesData {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}
