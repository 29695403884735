.navBar {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 40px;
  height: 50px;
  min-height: 80px;
  background-color: rgb(255, 255, 255);
  width: 100%;
}

.Navtitle {
  width: 30%;
  font-size: 1.875rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: #0290ff;
  padding-left: 20px;
}

.navLinks {
  padding-right: 160px;
  margin: 7px;
  width: 55%;
  padding-left: 10px;
  color: #121212;
}

.link {
  font-size: 0.813rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.9px;
  color: #121212;
  opacity: 0.5;
}

.active {
  font-size: 0.813rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  letter-spacing: 1.9px;
  color: #121212;
  opacity: 1;
}

.person-circle {
  border: 1px solid #e8e8e8;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bi-person-fill {
  color: #0290ff;
  cursor: pointer;
}
