.separatorHorizontal {
  min-width: 80%;
  border: 1px solid #cacaca77;
}

.formSection {
  width: 90%;
  margin-top: 20px;
  margin-bottom: 40px;
}

form {
  display: flex;
  margin-top: 15px;
  column-gap: 10px;
  margin-bottom: 90px;
}

.formHeader {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.25rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.18px;
  color: #888;
}

form input {
  box-sizing: border-box;
  border-radius: 4px;
  border: solid 1px #e8e8e8;
  background-color: rgb(255, 255, 255);
  min-width: 43%;
  height: 2.5rem;
  text-indent: 10px;
}

form input::placeholder {
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.15px;
  color: #888;
  opacity: 0.5;
}

form button {
  border-radius: 3px;
  background-color: #0290ff;
  border: 0;
  padding: 5px 10px;
  font-family: 'Roboto Slab', serif;
  font-size: 0.813rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: center;
  color: white;
  cursor: pointer;
}
